import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AnimationOptions } from 'ngx-lottie';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { RequestGeneralDetailsComponent } from 'src/app/components/request-general-details/request-general-details.component';
import { API_FAILING_ERROR_MESSAGE, ASSIGNMENTS, GENERAL_DETAILS, HOME_URL_PATH, PROPOSAL_DETAILS, PROPOSAL_ID, PROPOSAL_TYPES_URL_PATH, REQUESTOR, REQUEST_ID, REVIEW, STEP_TITLE_DEFAULT_CLASS, STEP_TITLE_ERROR_CLASS, SUBMIT, IFCMEMBER, SV_USER_ROLE, TAB_CONTACTS_TITLE_ERROR_CLASS, TAB_ENTITY_QUESTIONS_TITLE_ERROR_CLASS, TAB_ENTITY_SELECTION_TITLE_ERROR_CLASS, TAB_PROPOSED_SLATE_TITLE_ERROR_CLASS, TAB_SUMMARY_TITLE_ERROR_CLASS, TAB_TITLE_DEFAULT_CLASS, CONCUR, DECLINE, COORDINATOR, SV_PROPOSAL_STATUS, RETURNTOREQUESTOR, IFCGOVERNANCE, COMPLETE, WITHDRAW, TAB_IFCREVIEWERS_TITLE_ERROR_CLASS, SV_USER, RequesterProposalSubmissionNote, IFCSECRETARY, QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH } from 'src/app/constants/constants';
import { EmailNotification,ProposalEvent } from 'src/app/core/interfaces/EmailNotification';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';
import { DataServiceEntityQuestions } from 'src/app/core/services/data_service_entity_questions';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { DataServiceProposedSlate } from 'src/app/core/services/data_service_proposed_slate';
import { DataServiceProposal } from 'src/app/core/services/data_service_proposal';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { EmailService } from 'src/app/core/services/email_service';
import { Notes } from 'src/app/core/interfaces/notes';
import { Approval } from 'src/app/core/interfaces/approval';
import { DataServiceIFCReviewers } from 'src/app/core/services/data_service_ifc_reviewers';
import { IFCGroupsDTO } from 'src/app/core/interfaces/ifcReviewers';
import { DataServiceApprovals } from 'src/app/core/services/data_service_approvals';
import { Reviewer } from 'src/app/core/interfaces/reviewer';
import { DataServiceQualificationWithdrawalProposalOverview } from 'src/app/core/services/data_service_qualification_proposal_overview';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-qualification-withdrawal-proposal',
  templateUrl: './qualification-withdrawal-proposal.component.html',
  styleUrls: ['./qualification-withdrawal-proposal.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class QualificationWithdrawalProposalComponent {
  emailNotification!: EmailNotification
  confirmModal?: NzModalRef;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  isLoader!: boolean;
  isEnable: boolean = false;
  index = 0;
  stepGeneralDetails = GENERAL_DETAILS;
  stepProposalDetails = PROPOSAL_DETAILS;
  stepAssignment = ASSIGNMENTS;
  stepReview = REVIEW;
  stepSubmit = SUBMIT;
  stepGeneralDetailsClass = STEP_TITLE_DEFAULT_CLASS;
  stepProposalDetailsClass = STEP_TITLE_DEFAULT_CLASS;
  stepAssignmentClass = STEP_TITLE_DEFAULT_CLASS;
  entitySelectionTabClass = TAB_TITLE_DEFAULT_CLASS;
  entityQuestionTabClass = TAB_TITLE_DEFAULT_CLASS;
  summaryTabClass = TAB_TITLE_DEFAULT_CLASS;
  proposedSlateTabClass = TAB_TITLE_DEFAULT_CLASS;
  attachmentTabClass = TAB_TITLE_DEFAULT_CLASS;
  contactsTabClass = TAB_TITLE_DEFAULT_CLASS;
  commentsTabClass = TAB_TITLE_DEFAULT_CLASS;
  ifcRevewerTabClass = TAB_TITLE_DEFAULT_CLASS;
  ifcMember = IFCMEMBER;
  coordinator = COORDINATOR;
  requestor = REQUESTOR;
  stepAssignmentStatus = 'wait';
  stepTitle: string[] = [GENERAL_DETAILS, PROPOSAL_DETAILS, ASSIGNMENTS, REVIEW, SUBMIT];
  currentUserProfile!: ICvxClaimsPrincipal;
  stepDisable: boolean = true;
  stepGeneralDetailsDisable: boolean = false;
  stepReviewDisable: boolean = true;
  submitRequest: boolean = false;
  submitRequestSuccess: boolean = false;
  submitRequestFailed: boolean = false;
  currentStatusId: number = 0;
  proposalId: string = '';
  disableSubmit:boolean = false;
  enableClose:boolean = false;
  disableBack:boolean = false;
  userRoleTitle:any;
  displayReview:boolean = true;
  ifcActionType!: string;
  notes!: Notes;
  proposalSubmitTitle!:string;
  flagIFC: any;
  // legalEntityOwnershipRestructuringForm = this.fb.group({
  //   entityName: [null],
  //   entityComments: [null],
  //   summaryLegalActivities: [null],
  //   qualification:[null],
  //   withdrawal:[null]
  // });
  legalEntityQualificationWithdrawalForm = this.fb.group({
    entityName: [null],
    entityComments: [null],
    summaryLegalActivities: [null],
    qualification:[null],
    withdrawal:[null],
    jurisdictionDescription:[null],
  });

  qualificationWithdrawalProposalOverviewForm = this.fb.group({
    requestId: [null],
    summaryId: [null],
    overviewOfProposal: [null],
    businessReasonForProposal: [null],
    targetEffectiveDate: [null]
  });
  contactsForm = this.fb.group({
    requestId: [null],
    contactId: [null],
    coordinator: [null],
    additionalCcsForNotifications: [null],
    ifcRequired: [null]
  });
  commentsForm = this.fb.group({
    coordinator: [null],
    coordinatorEmail: [null],
    comment: [null],
    typeOfContact: [null]
  });
  ifcSecretaryForm = this.fb.group({
    dueDate: [null],
    concurDate: [null],
    ifcSecretary: [null]
  });

  confetti: AnimationOptions = {
    path: 'assets/confetti.json',
    loop: false,
    autoplay: true
  };
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  commentsData!: string;
  ifcApprovalPayload: IFCGroupsDTO[] = [];
  isDueDate!: boolean;
  isIFCTab!: boolean;
  isApprovalSubmitted!: boolean;
  isApprovalConcurredOrDeclined: boolean = false;
  isDisplayGeneralDetails: boolean = false;
  selectedTabIndexValue:number=0;

  @ViewChild(RequestGeneralDetailsComponent) childRequestGeneralDetails!: RequestGeneralDetailsComponent;
  @ViewChild(PageHeaderComponent) childPageHeaderComponent!: PageHeaderComponent;

  constructor(private router: Router, private fb: UntypedFormBuilder, private apiService: P30APIService, private authService: CalAngularService,
    private session: SessionStorageService, private cdr: ChangeDetectorRef, private dataservice: DataService,
    private modal: NzModalService,
    private dataServiceQualificationWithdrawalProposalOverview:DataServiceQualificationWithdrawalProposalOverview,
    private entityQuestionsDataService: DataServiceEntityQuestions,
    private entitySelectionDataService: DataServiceEntitySelection,
    private generalDetailsDataService: DataServiceGeneralDetails,
    private contactsDataService: DataServiceContacts,
    private proposalDataService: DataServiceProposal,
    private commentsDataService: DataServiceComments,
    private approvalDataService: DataServiceApprovals,
    private emailService:EmailService,
    private message: NzMessageService,
    private ifcReviewersService: DataServiceIFCReviewers) {
    this.getSubscribtionValues();
  }

  async ngOnInit() {
    this.ifcReviewersService.reviewNsubmitStatus(undefined);
    this.ifcReviewersService.isActionCompletd = false;
    let isTokenAvailable = await this.dataservice.getAuthorizationToken();
    if(isTokenAvailable){
      if (this.session.getItem(PROPOSAL_ID) == null) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        this.isDisplayGeneralDetails = await this.getProposalIdFromUrlPath();
        await this.getProposalResponse();
      } else {
        this.isDisplayGeneralDetails = true;
        await this.getProposalResponse();
        let approval = await this.approvalDataService.checkApprovalExist(this.session.getItem(SV_USER_ROLE).roleTitle);
        if(approval){
          this.isApprovalConcurredOrDeclined = true;
        }
      }
    }
    this.onFormChanges();
    this.contactsDataService.selectedContact.subscribe((data: any) => {
      if (data?.objectId) {
        this.stepAssignmentClass = STEP_TITLE_DEFAULT_CLASS;
        this.contactsTabClass = TAB_TITLE_DEFAULT_CLASS;
      }
    });
  }

  ifcMemberValidation(){
    this.index = 3;
    this.stepGeneralDetailsDisable = true;
    this.stepReviewDisable = false;
    this.displayReview = true;
    this.disableSubmit = true;
    this.disableBack = true;
    this.enableClose = true;
  }

  checkUserRole(roleTitle:any, statusId: any){
    switch(roleTitle){
      case REQUESTOR:{
        if(statusId !== 1){
          this.isLoader = false;
          this.index = 3;
          this.stepGeneralDetailsDisable = true;
          this.stepReviewDisable = false;
          this.disableSubmit = true;
          this.disableBack = true;
          this.enableClose = true;
        }else{
          this.stepDisable = false;
        }
        break;
      }
      case COORDINATOR:{
        if(statusId == 5 || statusId == 6 || statusId == 7 || statusId == 4){
          this.isLoader = false;
          this.index = 3;
          this.stepGeneralDetailsDisable = true;
          this.stepReviewDisable = false;
          this.disableSubmit = true;
          this.disableBack = true;
          this.enableClose = true;
        }else{
          this.isLoader = false;
          this.stepDisable = false;
        }
        break;
      }
      case IFCMEMBER:{
        if(statusId == 1){
          this.isLoader = false;
          this.stepDisable = false;
        }else{
          this.isLoader = false;
          this.ifcMemberValidation();
        }
        break;
      }
      case IFCSECRETARY:{
        if(statusId == 1){
          this.isLoader = false;
          this.stepDisable = false;
        }else{
          this.isLoader = false;
          this.ifcMemberValidation();
        }
        break;
      }
      default:{
        break;
      }
    }
  }

  async getProposalResponse() {
    const result:any = await new Promise((resolve)=>{
      this.isLoader = true;
      this.generalDetailsDataService.getProposal().subscribe({
        next: (output) => {
          if(output.body){
            resolve(output.body);
            this.isLoader = false;
          }else{
            this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
            this.userRoleTitle = this.dataservice.roleTitle;
          }
        },
        error: (err) => {
          console.log(`Unable to retrieve the proposal ${err}`);
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        }
      })
    })
    if(result){
      let statusId = this.dataservice.statuses.find(data=>data.name === result.status)?.statusId;
      this.session.setItem(SV_PROPOSAL_STATUS,this.dataservice.statuses.find(data=>data.name === result.status));
      this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
      this.userRoleTitle = this.dataservice.roleTitle;
      this.currentStatusId = Number(statusId)-1;
      this.checkUserRole(this.userRoleTitle,statusId);
      this.dataServiceQualificationWithdrawalProposalOverview.patchLegalEntitiesToFormGroup(this.isLoader, this.legalEntityQualificationWithdrawalForm)
      this.dataServiceQualificationWithdrawalProposalOverview.patchEntityQuestionsDataToFormGroup(this.legalEntityQualificationWithdrawalForm)
      this.dataServiceQualificationWithdrawalProposalOverview.patchOwnershipRestructuringProposalOverviewDataToFormGroup(this.qualificationWithdrawalProposalOverviewForm, this.isLoader);
      this.contactsDataService.patchContactsToFormGroup(this.contactsForm, this.isLoader);
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }

  }

  async getProposalIdFromUrlPath(){
    let arrVal:string[]=this.router.url.split('/');
    this.isLoader = true;
    return await new Promise<boolean>((resolve) =>{
      if(arrVal[arrVal.length-1].includes('P30')){
        this.proposalId = arrVal[arrVal.length-1];
        let arrReq:string[] = arrVal[arrVal.length-1].split('-');
        this.session.setItem(REQUEST_ID,arrReq[arrReq.length-1]);
        this.session.setItem(PROPOSAL_ID,this.proposalId);
        this.isLoader = false;
        resolve(true);
      }else{
        this.isLoader = false;
        resolve(true);
      }
    })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngOnDestroy(){
    this.qualificationWithdrawalProposalOverviewForm.reset();
    this.legalEntityQualificationWithdrawalForm.reset();
    this.commentsForm.reset();
  }

  receiveLoaderEmitValue(val: boolean) {
      this.isLoader = val;
  }

  receiveContactFormEmitValue(val: boolean) {
    if(val){
      this.stepAssignmentClass = STEP_TITLE_DEFAULT_CLASS;
    }
  }

  receivestepAssignmentClass(val: string) {
    this.stepAssignmentClass = val;
  }

  receiveStepGeneralDetailsClass(val: string) {
    this.stepGeneralDetailsClass = val;
  }

  receiveEnableEmitValue(val: boolean) {
    this.isEnable = val;
  }

  onIndexChange(index: number): void {
    this.index = index;
    if(this.stepTitle[this.index] != REVIEW){
      this.stepReviewDisable = true;
    }
  }

  navigateToPageComponent(data: string) {
    //this.router.navigate([data]);
    setTimeout(() => {
      this.router.navigate([data]);
    }, 2000);  

  }

  async pre(): Promise<void> {
    this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
    this.ifcReviewersService.reviewNsubmitStatus(undefined);
    this.ifcReviewersService.isActionCompletd = false;
    if (this.stepTitle[this.index] === this.stepTitle[0]) {
      this.index = 0;
      this.navigateToPageComponent(PROPOSAL_TYPES_URL_PATH);
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
        next: (user) => {
          this.generalDetailsDataService.getProposalIdAndRequestId(user.body.userId, 1, "").subscribe({
            next: (result) => {
              if(result?.body?.proposals?.length==1){
              this.dataservice.proposalType = result.body.proposals[0].proposalType;
              this.session.setItem(PROPOSAL_ID, result.body.proposals[0].proposalId);
              }
            },
            error: (err) => {
              this.message.error(this.apiErrorMSG);
              console.log("Unable to get proposal details!")
            },
            complete: () => {

            }
          })
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          console.log("Unable to get user profille!")
        },
        complete: () => {
        }
      })
    }
    if (this.stepTitle[this.index] === PROPOSAL_DETAILS) {
      if(this.selectedTabIndexValue >0){
        this.selectedTabIndexValue -=1;
        return;
      }
    }
    this.actionOnStepTitle(this.stepTitle[this.index]);
  }

  async next() {
    this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
    this.ifcReviewersService.reviewNsubmitStatus(undefined);
    this.ifcReviewersService.isActionCompletd = false;
    if (this.stepTitle[this.index] == GENERAL_DETAILS) {
      this.childRequestGeneralDetails.updateGeneralDetails();
      if (this.childRequestGeneralDetails.generalDetailsForm.valid) {
        this.stepGeneralDetailsClass = STEP_TITLE_DEFAULT_CLASS;
        this.index += 1;
      } else {
        this.message.error('Please complete the form before saving the proposal!');
      }
    }
    else if (this.stepTitle[this.index] == PROPOSAL_DETAILS) {
      //this.dataServiceQualificationWithdrawalProposalOverview.reviewAndValidateEntitySelection(this.legalEntityQualificationWithdrawalForm);
      if(this.selectedTabIndexValue >= 2){
      let result = await this.saveProposalDetails();
      if (result) {
        this.index += 1;
      } else {
        this.message.error('Please complete the forms before saving the proposal details!');
      }
    }
    else{
     let result = await this.saveProposalDetails();
     this.selectedTabIndexValue +=1;
    }
    }
  }

  async actionOnStepTitle(name: string) {
    switch (name) {
      case 'GENERAL_DETAILS': {
        if (this.session.getItem(REQUEST_ID)) {
          if (this.childRequestGeneralDetails.updateGeneralDetails()) {
            this.navigateToPageComponent(PROPOSAL_TYPES_URL_PATH);
          }
        } else {
          this.navigateToPageComponent(PROPOSAL_TYPES_URL_PATH);
        }
        break;
      }
      case 'PROPOSAL_DETAILS': {
        let result = await this.saveProposalDetails();
        if (result) {
          this.index -= 1;
        }
        break;
      }
      case 'ASSIGNMENTS': {
        this.submitContacts();
        if (this.commentsData) {
          this.submitComments();
        }
        if (this.ifcApprovalPayload) {
          this.submitIFCReviewersDetails()
        }
        this.index -= 1;
        break;
      }
      case 'REVIEW': {
        this.stepReviewDisable = true;
        this.index -= 1;
        break;
      }
      default : {
        this.index -= 1;
      }
    }
  }

  async deleteProposalAttachment() {
    this.apiService.getAttachments(this.proposalId).subscribe({
      next: (result) => {
        if (result.body) {
          for (let attach of result.body) {
            this.apiService.deleteAttachment(this.proposalId, attach.attachmentId, attach.fileName).subscribe({
              next: (result) => {

              }
            })
          }
        }
      },
      error: (err) => {
        this.message.error("Unable to delete attachment.");
      },
      complete: () => { }
    });
  }

  async deleteProposal() {
    if (this.session.getItem(PROPOSAL_ID)) {
      this.proposalId = this.session.getItem(PROPOSAL_ID)
      this.isLoader = true

      // delete attachments from database table and azure blob
      this.deleteProposalAttachment()

      this.apiService.deleteProposal(this.proposalId).subscribe({
        next: (result) => {
          return result
        },
        error: (err) => {
          this.message.error("Unable to delete proposal.");
          this.isLoader = false
        },
        complete: () => {
          this.navigateToPageComponent(HOME_URL_PATH);
          this.message.success("Successfully deleted your proposal.");
          this.session.removeItem(REQUEST_ID);
          this.session.removeItem(PROPOSAL_ID);
          this.navigateToPageComponent(HOME_URL_PATH);
          this.isLoader = false
        }
      })
    }
  }

  showDeletePopup(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: '<b style="color:#0081c5;">Are you sure you want to delete this whole proposal?</b>',
      nzContent: 'Delete will erase all data and delete this draft proposal.',
      nzOkText: "Delete",
      nzOkDanger: true,
      nzIconType: "exclamation-circle",
      nzMaskClosable: true,
      nzOnOk: () => this.deleteProposal()
    });
  }

  async updateProposal() {
    if (this.stepTitle[this.index] == GENERAL_DETAILS) {
      this.childRequestGeneralDetails.updateGeneralDetails();
      if (this.childRequestGeneralDetails.generalDetailsForm.valid) {
        this.stepGeneralDetailsClass = STEP_TITLE_DEFAULT_CLASS;
        this.navigateToPageComponent(HOME_URL_PATH);
      }
    }
    else if (this.stepTitle[this.index] == PROPOSAL_DETAILS) {
      this.dataServiceQualificationWithdrawalProposalOverview.checkBTNStatus(true);
      let result = await this.saveProposalDetails();
      if(result){
        this.navigateToPageComponent(HOME_URL_PATH);
      }
    }
    else if (this.stepTitle[this.index] == ASSIGNMENTS) {
      if(this.ifcApprovalPayload) {
        this.submitIFCReviewersDetails()
      }
      if (this.commentsData) {
        this.submitComments();
        this.navigateToPageComponent(HOME_URL_PATH);
      } else {
        this.submitContacts();
        this.navigateToPageComponent(HOME_URL_PATH);
      }
    }else if (this.stepTitle[this.index] == REVIEW) {
      this.navigateToPageComponent(HOME_URL_PATH);
    }
  }

  async saveProposalDetails(): Promise<boolean> {
    let po = await this.submitProposalOverview();
    let es = await this.submitQualificationLegalEntitySelection()
    let qwq=await this.submitQualificationWithdrawalQuestions();
    if (es && po && qwq) {
      this.message.success("Successfully saved your proposal details.");
      this.proposalId = this.session.getItem(PROPOSAL_ID)
      return Promise.resolve(true);
    } else {
      this.message.success("Unable to save your proposal details!");
      return Promise.resolve(false);
    }
  }

  async saveProposal() {
    if (await this.childRequestGeneralDetails.createGeneralDetails()) {
      this.getProposalResponse();
      this.stepDisable = false;
    }
  }

  async validateIFCReviewersComponent() {
    switch (this.isDueDate) {
      case (true): {
        this.flagIFC = true;
        this.stepAssignmentClass = STEP_TITLE_DEFAULT_CLASS;
        this.ifcRevewerTabClass = TAB_TITLE_DEFAULT_CLASS;
        this.ifcReviewersService.ifcConcurDateValue(true);
        this.submitIFCReviewers();
        break;
      }
      case (false): {
        this.stepAssignmentClass = STEP_TITLE_ERROR_CLASS;
        this.ifcRevewerTabClass = TAB_IFCREVIEWERS_TITLE_ERROR_CLASS;
        this.ifcReviewersService.ifcConcurDateValue(false);
        break;
      }
    }
  }

  async submitIFCReviewersDetails() {
    switch (this.isApprovalSubmitted) {
      case (true): {
        this.flagIFC = true;
        this.ifcReviewersService.isActionCompletd = true;
        this.ifcReviewersService.getIFCUpdateRecordsStatus(true);
        this.validateIFCReviewersComponent();
        break;
      }
      case (false): {
        if(this.isDueDate) {
          this.flagIFC = true;
          this.ifcReviewersService.isActionCompletd = true;
          this.ifcReviewersService.getIFCUpdateRecordsStatus(false);
          this.validateIFCReviewersComponent();
        } else {
          this.flagIFC = false;
          this.stepAssignmentClass = STEP_TITLE_ERROR_CLASS;
          this.ifcRevewerTabClass = TAB_IFCREVIEWERS_TITLE_ERROR_CLASS;
        }
        break;
      }
    }
  }

  async reviewSubmit() {
    this.ifcReviewersService.reviewNsubmitStatus(true);
    this.ifcReviewersService.isActionCompletd = true;
    if(this.isIFCTab) {
      this.submitIFCReviewersDetails();
    } else {
      this.flagIFC = true;
    }
    let isContactError: boolean;
    this.submitContacts();
    if (this.commentsData) {
      this.submitComments();
    }
    let flagGD = await this.generalDetailsDataService.reviewAndValidateGeneralDetails(this.dataservice.aqGeneralDetailsForm);
    let flagES = await this.dataServiceQualificationWithdrawalProposalOverview.reviewAndValidateEntitySelection(this.legalEntityQualificationWithdrawalForm);
    let flagEQ = await this.dataServiceQualificationWithdrawalProposalOverview.reviewAndValidateQualificationEntityQuestions(this.legalEntityQualificationWithdrawalForm);
    let flagQD = await this.dataServiceQualificationWithdrawalProposalOverview.reviewAndValidateQualificationDetails(this.legalEntityQualificationWithdrawalForm);
    let flagPO = await this.dataServiceQualificationWithdrawalProposalOverview.reviewAndValidateOwnershipRestructuringProposalOverview(this.qualificationWithdrawalProposalOverviewForm);
    let flagASG = await this.contactsDataService.reviewAndValidateContacts(this.contactsForm);
    if (this.contactsDataService.coordsInfoList.length > 0) {
      isContactError = false;
    } else {
      isContactError = true;
    }
    if (flagGD && flagES && flagEQ && flagQD && flagPO && flagASG && this.flagIFC) { 
      this.stepReviewDisable = false;
      this.index += 1;
      this.stepProposalDetailsClass = STEP_TITLE_DEFAULT_CLASS;
      this.entitySelectionTabClass = TAB_TITLE_DEFAULT_CLASS;
    } else if (!flagGD || !flagPO || !flagASG || !flagES || !this.flagIFC || !flagEQ || !flagQD ) {
      this.setTabClassValue(flagGD, flagPO, flagES,this.flagIFC,flagEQ);
      if (!flagASG && isContactError) {
        this.stepAssignmentClass = STEP_TITLE_ERROR_CLASS;
        this.contactsTabClass = TAB_CONTACTS_TITLE_ERROR_CLASS;
      }else {
        this.stepAssignmentClass = STEP_TITLE_DEFAULT_CLASS;
        this.contactsTabClass = TAB_TITLE_DEFAULT_CLASS;
      }
    }
  }

  async setTabClassValue(flagGD: any, flagPO: any, flagES: any,flagIFC:any,flagEQ:any) {
    this.stepProposalDetailsClass = TAB_TITLE_DEFAULT_CLASS;

    if (!flagGD) {
      this.stepGeneralDetailsClass = STEP_TITLE_ERROR_CLASS;
    }
    if (!flagPO) {
      this.stepProposalDetailsClass = STEP_TITLE_ERROR_CLASS;
      this.summaryTabClass = TAB_SUMMARY_TITLE_ERROR_CLASS;
    }
    if (!flagES) {
      this.stepProposalDetailsClass = STEP_TITLE_ERROR_CLASS;
      this.entitySelectionTabClass = TAB_ENTITY_SELECTION_TITLE_ERROR_CLASS;
    }
    if (!flagEQ) {
      this.stepProposalDetailsClass = STEP_TITLE_ERROR_CLASS;
      this.entitySelectionTabClass = TAB_SUMMARY_TITLE_ERROR_CLASS;
    }
    if (!flagIFC && !this.isDueDate) {
      this.stepAssignmentClass = STEP_TITLE_ERROR_CLASS;
      this.ifcRevewerTabClass = TAB_IFCREVIEWERS_TITLE_ERROR_CLASS;
    }
  }

  submitContacts() {
    this.contactsDataService.submitContacts(this.contactsForm, this.isLoader, this.contactsTabClass);
  }
  submitComments() {
    this.commentsDataService.submitComments(this.isLoader);
  }

  submitIFCReviewers() {
    this.ifcReviewersService.submitIfcForApproval(this.ifcSecretaryForm);
  }

  async submitProposalOverview(): Promise<boolean> {
    let result = await this.dataServiceQualificationWithdrawalProposalOverview.submitProposalOverview(this.qualificationWithdrawalProposalOverviewForm,this.legalEntityQualificationWithdrawalForm, this.isLoader, this.summaryTabClass);
    if(result){
      this.summaryTabClass = TAB_TITLE_DEFAULT_CLASS;
    }else{
      this.summaryTabClass = TAB_SUMMARY_TITLE_ERROR_CLASS;
    }
    return result;
  }

  async submitQualificationLegalEntitySelection(): Promise<boolean> {
    let result = await this.dataServiceQualificationWithdrawalProposalOverview.submitQualificationLegalEntitySelection(this.legalEntityQualificationWithdrawalForm);
    if (!result) {
      this.entitySelectionTabClass = TAB_ENTITY_SELECTION_TITLE_ERROR_CLASS;
      return Promise.resolve(false);
    } else {
      this.summaryTabClass = TAB_TITLE_DEFAULT_CLASS;
      return Promise.resolve(true);
    }
  }

  async submitQualificationWithdrawalQuestions(): Promise<boolean> {
    let result = await this.dataServiceQualificationWithdrawalProposalOverview.submitEntityQuestions(this.legalEntityQualificationWithdrawalForm, this.entityQuestionTabClass);
    if(result){
      this.entitySelectionTabClass = TAB_TITLE_DEFAULT_CLASS;
    }else{
      this.entitySelectionTabClass = TAB_ENTITY_SELECTION_TITLE_ERROR_CLASS;
    }
    return result;
  }

  async triggerEmail(){
    (await this.emailService.sent_email_notification(this.emailNotification)).subscribe({
      next: (event) => {
        console.log("Email sent successfully!")
      },
      error: (err) => { console.log("Error sending email.") },
      complete: () => { }
    });
  }

  sendEmailNotification(event:any) {
    this.isLoader = true;
    this.proposalDataService.getProposalInfo(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (proposalresponse) => {
        this.contactsDataService.getContacts(this.proposalId).subscribe({
          next: (contactResponse) => {
            this.emailNotification = {
              proposalId: proposalresponse.body.proposalId,
              title: proposalresponse.body.title,
              proposalType: proposalresponse.body.proposalType,
              proposalPathName:QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH,
              businessSegment: proposalresponse.body.businessSegment,
              region: proposalresponse.body.region,
              requestedFor: proposalresponse.body.requestedFor,
              requestedForEmail: proposalresponse.body.requestedForEmail,
              requestedBy: proposalresponse.body.requestedBy,
              requestedByEmail: proposalresponse.body.requestedByEmail,
              coordinatorEmail: contactResponse.body[0].coordinatorEmail.includes(';') ? contactResponse.body[0].coordinatorEmail.split(';') : [contactResponse.body[0].coordinatorEmail],
              coordinator: contactResponse.body[0].coordinator.includes(';') ? contactResponse.body[0].coordinator.split(';') : [contactResponse.body[0].coordinator],
              additionalCc: contactResponse.body[0].additionalCcsForNotifications.includes(';') ? contactResponse.body[0].additionalCcsForNotifications.split(';') : [contactResponse.body[0].additionalCcsForNotifications],
              additionalCcEmail: contactResponse.body[0].additionalCcsForNotificationsEmail.includes(';') ? contactResponse.body[0].additionalCcsForNotificationsEmail.split(';') : [contactResponse.body[0].additionalCcsForNotificationsEmail],
              status: proposalresponse.body.status,
              completionNotes:contactResponse.body[0].completionNotes,
              event: event,
              notes: RequesterProposalSubmissionNote,
            };
            this.apiService.getApprovals(this.proposalId).subscribe({
              next: (approval) => {
                if (approval.body) {
                  let roleTitle = this.session.getItem(SV_USER_ROLE).roleTitle;
                  let approvals: Approval[] = []
                  approvals = [...approval.body];
                  // store ifc governance (coordinator) approval data
                  let approval_ifc_gov_data = <Approval> approvals.find(e=>e.type==IFCGOVERNANCE);
                   // store ifc member (Legal,Tax,Treasury and Comptrollers) approval data
                  if(approval_ifc_gov_data)
                    this.emailNotification.ifcGovernanceComments = approval_ifc_gov_data.comment;
                  let approval_ifc_member_data = <Approval> approvals.find(e=>e.type==roleTitle);
                  if(approval_ifc_member_data)
                    if(approval_ifc_member_data.status == false)
                      this.emailNotification.ifcComments = approval_ifc_member_data.comment;
                }
                this.apiService.getComments(this.proposalId).subscribe({
                  next: (comment) => {
                    if (comment.body) {
                      if(comment.body[0].comment)
                        this.emailNotification.coordinatorNotes = comment.body[0].comment;
                        this.triggerEmail();
                    }else{
                      this.triggerEmail();
                    }
                  },
                  error: (err) => {
                    console.log(`Unable to retrieve approvals ${err}`);
                    this.isLoader = false;
                  },
                  complete: () => {
                    this.isLoader = false;
                  }
                })
              },
              error: (err) => {
                console.log(`Unable to retrieve approvals ${err}`);
                this.isLoader = false;
              },
              complete: () => {
                this.isLoader = false;
              }
            });

          },
          error: (err) => {
            console.log("Error getting contacts.");
            this.isLoader = false;
           },
          complete: () => {
            this.isLoader = false;
           }
        })
      },
      error: (err) => {
        console.log("Error getting proposal.");
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
       }
    })
  }
  saveHistory(proposalId:string,action: string){
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe(output =>{
      if(output.status==200){
        const data = {
          "user": `${output.body.firstName} ${output.body.lastName}`,
          "userEmail": output.body.emailAddress,
          "userRoleId": output.body.userRoleId,
          "action": action,
          "date": new Date().toLocaleString("en-US")
        }
        this.apiService.createHistory(proposalId,data).subscribe({
          next: (event)=>{

          },
          error: (err) => {
            console.log("Unable to save history",err);
          },
          complete: () => {

          }
        })
      }else{
        console.log("Unable to retrieve user information");
      }
    })

  }


  submitProposal() {
    this.generalDetailsDataService.updateProposalStatus(2).subscribe({
      next: (event) => {
        // udpate status to pending governance
        this.currentStatusId = 1;
        //send Email to Reviwer
        this.sendEmailNotification(ProposalEvent.Submit_Proposal);
        this.saveHistory(this.session.getItem(PROPOSAL_ID),"Draft submitted for governance review")
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        this.submitRequestFailed = true;
        this.submitRequestSuccess = false;
        this.stepDisable = true;
        this.stepReviewDisable = true;
        this.stepGeneralDetailsDisable = true;
        console.log("Unable to update proposal status",err);
      },
      complete: () => {
        this.index += 1;
        this.submitRequestSuccess = true;
        this.submitRequest = true;
        this.stepDisable = true;
        this.stepReviewDisable = true;
        this.stepGeneralDetailsDisable = true;
        // clear session and data service variables
        this.clearTemptData();
      }
    })

  }

  clearTemptData() {
    this.dataservice.contactsForm = null;
    this.proposalId = this.session.getItem(PROPOSAL_ID);
    // Remove request id session variable
    this.session.removeItem(REQUEST_ID);
    this.session.removeItem(PROPOSAL_ID);
  }

  onFormChanges() {
    this.qualificationWithdrawalProposalOverviewForm.valueChanges.subscribe(val => {
      if (this.qualificationWithdrawalProposalOverviewForm.valid) {
        this.summaryTabClass = TAB_TITLE_DEFAULT_CLASS;
        this.stepClassChange();
      }
    });
    this.contactsForm.valueChanges.subscribe(val => {
      if (this.contactsForm.valid) {
        this.stepAssignmentClass = STEP_TITLE_DEFAULT_CLASS;
      }
    });
    this.ifcSecretaryForm.valueChanges.subscribe(val => {
      if (this.ifcSecretaryForm.valid) {
        this.ifcRevewerTabClass = STEP_TITLE_DEFAULT_CLASS;
      }
    });
  }

  stepClassChange() {
    if (this.summaryTabClass.includes('default')) {
      this.stepProposalDetailsClass = STEP_TITLE_DEFAULT_CLASS;
    }
  }

  receiveFailErr(eventVal: boolean) {
    this.submitRequestFailed = !eventVal;
    this.submitRequestSuccess = false;
    this.stepDisable = false;
    this.stepReviewDisable = false;
    this.stepGeneralDetailsDisable = false;
  }

  getSubscribtionValues() {
    this.commentsDataService.commentData$.subscribe((val: string) => {
      this.commentsData = val;
    });
  }

  isIFCTabActive(event: any) {
    switch(event) {
      case true: {
        this.isIFCTab = true;
        break;
      }
      case 'default': {
        this.isIFCTab = false;
        break;
      }
    }
  }

  isApprovalSubmit(event: any) {
    this.isApprovalSubmitted = event;
  }

  ifcReviewersPayload(event: any) {
    this.ifcApprovalPayload = event;
    this.ifcApprovalPayload.every((x : IFCGroupsDTO) => {
      if(x?.dueDate === '' || x?.dueDate === null) {
        this.isDueDate = false;
      } else {
        this.isDueDate = true;
      }
    })
  }

  closeProposal(){
    this.navigateToPageComponent(HOME_URL_PATH);
  }
  concurProposal(){
    this.notes ={
      role: this.userRoleTitle,
      statusId: this.currentStatusId+1,
      actionType:CONCUR,
      concurType:CONCUR,
      title: "Add Comments & Submit"
    }
    this.isLoader = true;
    this.createOrUpdateApproval();
  }

  declineProposal(){
    this.notes ={
      role: this.userRoleTitle,
      statusId: this.currentStatusId+1,
      actionType:DECLINE,
      concurType:DECLINE,
      title: "Add Comments & Submit"
    }
    this.displayReview = false;
  }
  receiveEnableReviewValue(event:boolean){
    if(event){
      this.displayReview = true;
    }else{
      this.displayReview = false;
    }
  }
  ifcGovernanceConcur(){
    this.notes ={
      role: this.userRoleTitle,
      statusId: this.currentStatusId+1,
      actionType:CONCUR,
      concurType:CONCUR,
      title: "Add Concur Notes & Submit"
    }
    this.displayReview = false;
  }
  ifcGovernanceComplete(){
    this.notes ={
      role: this.userRoleTitle,
      statusId: this.currentStatusId+1,
      actionType:COMPLETE,
      concurType:COMPLETE,
      title: "Add Completion Notes & Submit"
    }
    this.displayReview = false;
  }
  ifcGovernanceWithdraw(){
    this.notes ={
      role: this.userRoleTitle,
      statusId: this.currentStatusId+1,
      actionType:WITHDRAW,
      concurType:WITHDRAW,
      title: "Add Withdraw Comments & Submit"
    }
    this.displayReview = false;
  }
  returnToRequestor(){
    this.notes ={
      role: this.userRoleTitle,
      statusId: this.currentStatusId+1,
      actionType:RETURNTOREQUESTOR,
      concurType:CONCUR,
      title: "Confirm Return to Requestor Notes & Submit"
    }
    this.displayReview = false;
  }

  receiveSubmitNote(event:any){
    if(event.success){
      switch(event.role){
        case COORDINATOR:{
          if(event.typeOfComment === RETURNTOREQUESTOR){
            this.updateProposalStatus(1,RETURNTOREQUESTOR,"Proposal returned to requestor");
            this.sendEmailNotification(ProposalEvent.Return_To_Requestor);
            this.proposalSubmitTitle = "Returned to Requestor";
          }
          else if(event.typeOfComment === CONCUR && !this.isIFCTab){
            this.updateProposalStatus(4,IFCGOVERNANCE,"Governance concurred");
            this.sendEmailNotification(ProposalEvent.Concur_IFC_Not_Required);
          }
          else if(event.typeOfComment === CONCUR && this.isIFCTab){
            this.updateProposalStatus(3,IFCGOVERNANCE,"Governance concurred & proposal pending IFC concurrence");
            this.sendEmailNotification(ProposalEvent.Concur_IFC_Required);
          }
          else if(event.typeOfComment === COMPLETE){
            // We have to add logic to check ifc required from assignment page
            this.updateProposalStatus(7,COMPLETE,"Proposal completed");
            this.sendEmailNotification(ProposalEvent.Complete_Proposal);
          }
          else if(event.typeOfComment === WITHDRAW){
            // We have to add logic to check ifc required from assignment page
            this.updateProposalStatus(6,WITHDRAW,"Proposal withdrawn");
            this.sendEmailNotification(ProposalEvent.Withdraw);
          }
          break;
        }
        case IFCMEMBER:{
          if(event.typeOfComment === CONCUR && event.role !== COORDINATOR){
            this.updateProposalStatus(3,event.approverType,`Proposal concurred`);
          }else if(event.typeOfComment === DECLINE){
            this.updateProposalStatus(3,event.approverType,`Proposal declined`);
            this.sendEmailNotification(ProposalEvent.Decline_Proposal);
          }
          break;
        }
        default:{
          break;
        }
      }

    }
  }
  updateProposalStatus(statusId:number,typeOfNotification:string,historyComment:string){
    this.generalDetailsDataService.updateProposalStatus(statusId).subscribe({
      next: (event) => {
        this.currentStatusId = statusId-1;
        if(typeOfNotification === this.userRoleTitle || typeOfNotification === WITHDRAW){
          this.saveHistory(this.session.getItem(PROPOSAL_ID),historyComment);
        }
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        this.submitRequestFailed = true;
        this.submitRequestSuccess = false;
        this.stepDisable = true;
        this.stepReviewDisable = true;
        this.stepGeneralDetailsDisable = true;
        console.log("Unable to update proposal status",err);
      },
      complete: () => {
        this.index += 1;
        this.submitRequestSuccess = true;
        this.submitRequest = true;
        this.stepDisable = true;
        this.stepReviewDisable = true;
        this.stepGeneralDetailsDisable = true;
      }
    })
  }
  receiveIFCApprove(event: boolean) {
    if(event){
      this.generalDetailsDataService.updateProposalStatus(5).subscribe({
        next: (out) => {
          // udpate status to IFC endorsed
          this.currentStatusId = 4;
          //send Email to the respective team
          this.sendEmailNotification(ProposalEvent.All_IFC_Roles_Concur);
          this.saveHistory(this.session.getItem(PROPOSAL_ID),"Captured all IFC concurrences and moved proposal to IFC Endorsed");
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          console.log("Unable to update proposal status",err);
        },
        complete: () => {
          // clear session and data service variables
        }
      })
    }

  }
  receiveResetEmailTrigger(event:boolean){
    if(event){
      this.sendEmailNotification(ProposalEvent.Concurrence_Reset);
    }
  }
  receiveReminderEmailTrigger(event:boolean){
    if(event){
      this.sendEmailNotification(ProposalEvent.Send_Reminder_Email);
    }
  }
  approvals:Approval[] = [];
  async createOrUpdateApproval(){
    this.apiService.getReviewers().subscribe({
      next: (reviewer) => {
        if (reviewer.body) {
          let reviewers: Reviewer[] = [...reviewer.body];
          let roleTitle =  this.session.getItem(SV_USER_ROLE).roleTitle;
          if(roleTitle === COORDINATOR){
            roleTitle = IFCGOVERNANCE;
          }
          let reviewerData = reviewers.find(e => e.type === roleTitle);
          this.apiService.getApprovals(this.session.getItem(PROPOSAL_ID)).subscribe({
            next: (approval) => {
              const data: Approval = {
                requestId: this.session.getItem(REQUEST_ID),
                reviewerId: reviewerData?.reviewerId,
                type: reviewerData?.type,
                approverId: this.session.getItem(SV_USER).userId,
                approverEmail: this.session.getItem(SV_USER).emailAddress,
                status: this.notes.concurType === CONCUR,
                concurDate: new Date().toLocaleString("en-US"),
                comment: '',
                reset: false
              }
              if (approval.body) {
                let approvals: Approval[] = [...approval.body];
                let approvalsId: any = approvals.find(e => e.type === reviewerData?.type)?.approvalsId;
                data.dueDate = approvals.find(e => e.type === reviewerData?.type)?.dueDate;
                if(approvalsId){
                  this.updateApproval(approvalsId,data);
                }else{
                  data.dueDate = '';
                  this.createApproval(data);
                }
              } else {
                data.dueDate = '';
                this.createApproval(data);
              }
            },
            error: (err) => {
              console.log("unable to create or update approval",err);
            },
            complete: () => {
              if(this.notes.concurType === CONCUR){
                this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                  this.session.getItem(PROPOSAL_ID),
                  `Proposal concurred`)
              }
              this.isLoader = false;
            }
          })
        }
      },
      error: (err) => { },
      complete: () => { }
    })
  }

  updateApproval(approvalsId: number,data: Approval){
    this.apiService.updateApprovals(this.session.getItem(PROPOSAL_ID), approvalsId, data).subscribe((response:any) => {
      if(response.body) {
        this.getApprovalsRecords();
        let payload = {
          success:true,
          typeOfComment:this.notes.actionType,
          role: this.notes.role,
          approverType:data.type
        }
        setTimeout(() => {
          this.isLoader = true;
          if(!this.checkAllIFCMembersApproved()) {
            this.receiveSubmitNote(payload);
          } else {
            this.receiveIFCApprove(true);
          }
        }, 2000);
      }
    })
  }

  async getApprovalsRecords() {
    let approvalsRecords = await this.ifcReviewersService.getIFCApprovals();
    approvalsRecords.subscribe((res:any) => {
      if(res.body) {
        this.approvals = [];
        this.approvals = [...res['body']]
      }
    })
  }

  checkAllIFCMembersApproved(){
    return this.approvals.every(e=>e.status == true && e.requestId == this.session.getItem(REQUEST_ID));
  }

  createApproval(data: Approval){
    this.apiService.createApprovals(this.session.getItem(PROPOSAL_ID), data).subscribe({
      next: (result) => {
      },
      error: (err) => {
        console.log(`Unable to save concur decline record ${err}`);
      },
      complete: () => {
        let payload = {
          success:true,
          typeOfComment:this.notes.actionType,
          role: this.notes.role,
          approverType:data.type
        }
        this.receiveSubmitNote(payload);
      }
    })
  }

  receiveTabIndexEmitValue(val: any) {
    this.selectedTabIndexValue = val;
  }
}
